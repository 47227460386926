const cookieLoginName = "nani_oneclass_resource_platform_token";
function getNaniOneClassCookie(name) {
	const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
	return v ? v[2] : null;
}

export const getTubeList = async (payload) => {
	console.log("payload", payload);
	//const { SchoolYear, Edu, Subject, Version, Book, filename, pageNum, pageSize } = payload;
	const { year, education, subject, version, books, filename, pageNum, pageSize } = payload;
	var data = {
		SchoolYear: year,
		Edu: education,
		Subject: subject,
		Version: version,
		Book: books,
		filename,
		pageNum,
		pageSize,
	};
	const headers = {
		Authorization: getNaniOneClassCookie(cookieLoginName),
	};

	var res = await fetch(
		`${process.env.REACT_APP_ONETUBE_API}/api/Stored?SchoolYear=${year}&Edu=${education}&Subject=${subject}&Version=${version}&Book=${books}&`,
		{
			headers,
			method: "GET",
		},
	).then((response) => response.json());
	console.log("res", res);
	return res;
};
