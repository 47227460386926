export const ROLES = {
	Editor: "編輯",
};

export const COMMON_TEXT = {
	CREATE: "create",
	EDIT: "edit",
	KNOWLEDGE: "KNOWLEDGE",
	SUB_TOPIC: "SUB_TOPIC",
	TOPIC: "TOPIC",
	NO_HIERARCHY: "NO_HIERARCHY",
	OTHER: "OTHER",
};

export const POPUP_TITLE = {
	create: "新增",
	edit: "編輯",
};

export const TOPIC = {
	"{0}-{1}": "舊綱",
	"{0}{1}": "新綱",
};

export const LANGUAGE_GROUP = ["PC", "EN", "EL", "CH"];

export const EDUCATIONAL = [
	{
		name: "國小",
		value: "E",
	},
	{
		name: "國中",
		value: "J",
	},
	{
		name: "高中",
		value: "H",
	},
];

export const FILE_CHECK_STUTS = {
	SAVED_CONFLICT: "SavedConflict", //入庫衝突
	UNSAVED_CONFLICT: "UnSavedConflict", //未入庫衝突
	PROCESSING_CONFLICT: "ProcessingConflict", //檔案處理中
	SAME_FILE_NAME: "SameFileName", //重複名稱
};

export const AUTHORITY = {
	View: 0,
	Edit: 0,
	Lock: 0,
	Import: 0,
};

export const ITEM_TYPE = [
	{ education: "J", subject: "英語", name: "對話動畫", attribute: "N" },
	{ education: "J", subject: "英語", name: "閱讀動畫", attribute: "N" },
	{ education: "J", subject: "英語", name: "俚語動畫", attribute: "N" },
	{ education: "J", subject: "英語", name: "文法動畫", attribute: "N" },
	{ education: "J", subject: "英語", name: "Info動畫", attribute: "N" },
	{ education: "J", subject: "英語", name: "OneClass影片", attribute: "N" },
	{ education: "J", subject: "國文", name: "作者介紹", attribute: "N" },
	{ education: "J", subject: "國文", name: "課文影音", attribute: "N" },
	{ education: "J", subject: "國文", name: "注釋影音", attribute: "N" },
	{ education: "J", subject: "國文", name: "教學影音", attribute: "N" },
	{ education: "J", subject: "國文", name: "全文朗讀", attribute: "N" },
	{ education: "J", subject: "國文", name: "策略引導", attribute: "N" },
	{ education: "J", subject: "國文", name: "OneClass影片", attribute: "N" },
	{ education: "E", subject: "社會", name: "動畫", attribute: "N" },
	{ education: "E", subject: "社會", name: "引導動畫", attribute: "N" },
	{ education: "E", subject: "社會", name: "補充動畫", attribute: "N" },
	{ education: "E", subject: "社會", name: "小學堂", attribute: "N" },
	{ education: "E", subject: "社會", name: "圖說", attribute: "N" },
	{ education: "E", subject: "社會", name: "3D動畫", attribute: "N" },
	{ education: "E", subject: "社會", name: "口袋桌遊", attribute: "N" },
	{ education: "E", subject: "社會", name: "影片", attribute: "N" },
	{ education: "E", subject: "社會", name: "OneClass影片", attribute: "N" },
	{ education: "J", subject: "地理", name: "教學動畫", attribute: "N" },
	{ education: "J", subject: "地理", name: "教學影片", attribute: "N" },
	{ education: "J", subject: "地理", name: "OneClass影片", attribute: "N" },
	{ education: "J", subject: "歷史", name: "教學動畫", attribute: "N" },
	{ education: "J", subject: "歷史", name: "教學影片", attribute: "N" },
	{ education: "J", subject: "歷史", name: "OneClass影片", attribute: "N" },
	{ education: "J", subject: "公民", name: "教學動畫", attribute: "N" },
	{ education: "J", subject: "公民", name: "教學影片", attribute: "N" },
	{ education: "J", subject: "公民", name: "OneClass影片", attribute: "N" },
	{ education: "E", subject: "健體", name: "教學動畫", attribute: "N" },
	{ education: "E", subject: "健體", name: "教學影音", attribute: "N" },
	{ education: "E", subject: "綜合", name: "動畫", attribute: "N" },
	{ education: "E", subject: "綜合", name: "影片", attribute: "N" },
	{ education: "E", subject: "自然與生活科技", name: "課程影片", attribute: "N" },
	{ education: "E", subject: "自然與生活科技", name: "動畫", attribute: "漫畫動畫" },
	{ education: "E", subject: "自然與生活科技", name: "動畫", attribute: "課程動畫" },
	{ education: "E", subject: "自然與生活科技", name: "動畫", attribute: "科學閱讀動畫" },
	{ education: "E", subject: "自然與生活科技", name: "實驗影片", attribute: "課程實驗" },
	{ education: "E", subject: "自然與生活科技", name: "實驗影片", attribute: "延伸實驗" },
	{ education: "E", subject: "自然與生活科技", name: "科普素養影音", attribute: "N" },
	{ education: "E", subject: "自然與生活科技", name: "補充影片", attribute: "N" },
	{ education: "E", subject: "自然與生活科技", name: "OneClass影片", attribute: "N" },
	{ education: "J", subject: "自然與生活科技", name: "教學動畫", attribute: "N" },
	{ education: "J", subject: "自然與生活科技", name: "教學影片", attribute: "N" },
	{ education: "J", subject: "自然與生活科技", name: "實驗影片", attribute: "N" },
	{ education: "J", subject: "自然與生活科技", name: "實驗與活動影片", attribute: "N" },
	{ education: "J", subject: "自然與生活科技", name: "補充影片", attribute: "N" },
	{ education: "J", subject: "自然與生活科技", name: "OneClass影片", attribute: "N" },
	{ education: "E", subject: "國語", name: "注音動畫", attribute: "N" },
	{ education: "E", subject: "國語", name: "歌曲", attribute: "N" },
	{ education: "E", subject: "國語", name: "伴唱", attribute: "N" },
	{ education: "E", subject: "國語", name: "律動示範", attribute: "N" },
	{ education: "E", subject: "國語", name: "律動教學", attribute: "N" },
	{ education: "E", subject: "國語", name: "聆聽故事", attribute: "N" },
	{ education: "E", subject: "國語", name: "延伸故事", attribute: "N" },
	{ education: "E", subject: "國語", name: "引導動畫", attribute: "N" },
	{ education: "E", subject: "國語", name: "作者動畫", attribute: "N" },
	{ education: "E", subject: "國語", name: "策略引導", attribute: "N" },
	{ education: "E", subject: "國語", name: "標點符號", attribute: "N" },
	{ education: "E", subject: "國語", name: "語文天地", attribute: "N" },
	{ education: "E", subject: "國語", name: "動畫", attribute: "N" },
	{ education: "E", subject: "國語", name: "影片", attribute: "N" },
	{ education: "E", subject: "國語", name: "寫作動畫", attribute: "N" },
	{ education: "E", subject: "國語", name: "教學影片", attribute: "N" },
	{ education: "E", subject: "國語", name: "授權影片", attribute: "N" },
	{ education: "E", subject: "國語", name: "授權影片", attribute: "N" },
	{ education: "E", subject: "國語", name: "OneClass影片", attribute: "N" },
	{ education: "E", subject: "數學", name: "布題解說", attribute: "N" },
	{ education: "E", subject: "數學", name: "布題解說", attribute: "N" },
	{ education: "E", subject: "數學", name: "布題解說", attribute: "N" },
	{ education: "E", subject: "數學", name: "布題解說", attribute: "N" },
	{ education: "E", subject: "數學", name: "章頭動畫", attribute: "N" },
	{ education: "E", subject: "數學", name: "補充動畫", attribute: "N" },
	{ education: "E", subject: "數學", name: "補充影片", attribute: "N" },
	{ education: "E", subject: "數學", name: "OneClass影片", attribute: "N" },
	{ education: "J", subject: "數學", name: "概念動畫", attribute: "N" },
	{ education: "J", subject: "數學", name: "數學家", attribute: "N" },
	{ education: "J", subject: "數學", name: "素養動畫", attribute: "N" },
	{ education: "J", subject: "數學", name: "OneClass影片", attribute: "N" },
	{ education: "E", subject: "生活", name: "主題動畫", attribute: "N" },
	{ education: "E", subject: "生活", name: "動畫", attribute: "N" },
	{ education: "E", subject: "生活", name: "品格動畫", attribute: "N" },
	{ education: "E", subject: "生活", name: "影片", attribute: "N" },
	{ education: "E", subject: "生活", name: "示範", attribute: "N" },
	{ education: "E", subject: "生活", name: "講解", attribute: "N" },
	{ education: "E", subject: "生活", name: "練習", attribute: "N" },
	{ education: "E", subject: "生活", name: "技法", attribute: "N" },
	{ education: "E", subject: "生活", name: "補充技法", attribute: "N" },
];

export const VIDEO_TYPE = [
	{ name: "自製", value: "自製" },
	{ name: "授權", value: "授權" },
	{ name: "家教", value: "家教" },
];

export const STUDY_TYPE = [
	{ name: "自學資源", value: "自學資源" },
	{ name: "深度學習", value: "深度學習" },
];

export const KNOWLEDGE_TYPE = [
	{ name: "Y", value: "Y" },
	{ name: "N", value: "N" },
];

export const AUTHORIZE_TYPE = [
	{ name: "東森", title: "台灣1001個故事", key: "國小國語" },
	{ name: "三立", title: "用心看臺灣301", key: "國小社會" },
	{ name: "公視", title: "用心看臺灣301", key: "國小社會" },
	{ name: "中華文化永續發展基金會授權", title: "馮翊綱說人解字", key: "國中國文" },
	{ name: "中視", title: "大陸尋奇", key: "國中國文" },
	{ name: "臺北觀光局", title: "臺北孔廟三部曲", key: "國中國文" },
	{ name: "東森新聞台", title: "台灣1001個故事", key: "國中國文" },
	{ name: "切音樂電影", title: "甜蜜的負荷(歌曲)", key: "國中國文" },
	{ name: "泛科知識股份有限公司", title: "PanSci 泛科學", key: "國中國文" },
	{ name: "臺灣吧Taiwan Bar", title: "臺灣吧Taiwan Bar", key: "國中國文" },
	{ name: "臺北市政府文化局", title: "梁實秋故居", key: "國中國文" },
	{ name: "公視 台灣特有種", key: "國中國文" },
	{ name: "中華語文知識庫", title: "漢字說故事", key: "國小國語" },
	{ name: "志祺七七 X 圖文不符", title: "志祺七七 X 圖文不符", key: "國小國語" },
	{ name: "吳若權幸福書房", title: "momo這一家  ", key: "國小國語" },
	{ name: "吳若權幸福書房", title: "", key: "國小國語" },
	{ name: "玩創教育學院  玩創教育學院 ", key: "國小國語" },
	{ name: "大愛", title: "大愛新聞", key: "國小國語" },
	{ name: "聯合知識庫(聯合線上)", title: "字。在生活", key: "國小國語" },
	{ name: "法鼓山  心靈環保兒童生活教育動畫 ", key: "國小國語" },
	{ name: "詹大衛 Gentleman X", key: "國小國語" },
	{ name: "璽印篆刻  璽印篆刻 ", key: "國小國語" },
	{ name: "華夏見方 動畫說漢字", key: "國小國語" },
	{ name: "隻隻", title: "隻隻", key: "國小國語" },
	{ name: "華視", title: "華視新聞雜誌", key: "國小國語" },
	{ name: "麥卡貝  一日系列", key: "國小國語" },
	{ name: "三立  愛玩客", key: "國小國語" },
	{ name: "親子天下  親子天下  ", key: "國小國語" },
	{ name: "YOYOTV", title: "", key: "國小國語" },
	{ name: "伊甸基金會", title: "", key: "國小國語" },
	{ name: "人間衛視", title: "", key: "國小國語" },
	{ name: "麥當勞叔叔之家慈善基金會", title: "", key: "國小國語" },
	{ name: "臺灣1001個故事", title: "", key: "國小國語" },
	{ name: "東森", title: "台灣1001個故事", key: "國小自然" },
	{ name: "東森", title: "好好玩自然", key: "國小自然" },
	{ name: "東森", title: "Hello!瑪奇課", key: "國小自然" },
	{ name: "東森", title: "酷酷兄弟 生活大爆炸", key: "國小自然" },
	{ name: "東森", title: "東森新聞", key: "國小自然" },
	{ name: "東森", title: "跟著食物去旅行", key: "國小自然" },
	{ name: "公視", title: "流言追追追", key: "國小自然" },
	{ name: "公視", title: "毛起來幸福站 動物排排站", key: "國小自然" },
	{ name: "公視", title: "台灣特有種", key: "國小自然" },
	{ name: "公視", title: "我們的島", key: "國小自然" },
	{ name: "公視", title: "公視新聞", key: "國小自然" },
	{ name: "公視", title: "成語賽恩思", key: "國小自然" },
	{ name: "公視", title: "下課花路米", key: "國小自然" },
	{ name: "大愛", title: "生活裡的科學", key: "國小自然" },
	{ name: "大愛", title: "呼叫妙博士", key: "國小自然" },
	{ name: "大愛", title: "發現", key: "國小自然" },
	{ name: "大愛", title: "人文講堂", key: "國小自然" },
	{ name: "大愛", title: "大愛新聞", key: "國小自然" },
	{ name: "大愛", title: "小主播看天下", key: "國小自然" },
	{ name: "泛科學", title: "科學大爆炸", key: "國小自然" },
	{ name: "泛科學", title: "可能性調查署", key: "國小自然" },
	{ name: "三立", title: "臺灣全記錄", key: "國小自然" },
	{ name: "三立", title: "三立新聞", key: "國小自然" },
	{ name: "LIS", title: "自然系列地科｜天文學", key: "國小自然" },
	{ name: "LIS", title: "自然系列物理｜電磁學", key: "國小自然" },
	{ name: "民視", title: "民視新聞", key: "國小自然" },
	{ name: "中視", title: "科學補給站", key: "國小自然" },
	{ name: "臺灣吧 TaiwanBar", title: "實驗科學吧", key: "國小自然" },
	{ name: "新唐人亞太台", title: "", key: "國小自然" },
	{ name: "經濟日報EDN (聯合知識庫)", title: "", key: "國小自然" },
	{ name: "iStock", title: "", key: "國小自然" },
	{ name: "shutterstock", title: "", key: "國小自然" },
	{ name: "TSC攝影", title: "", key: "國小自然" },
	{ name: "行政院農業委員會 (農委會)", title: "土地的微笑", key: "國小自然" },
	{ name: "行政院環境保護署(環保署)", title: "", key: "國小自然" },
	{ name: "行政院經濟部能源局(能源局)", title: "", key: "國小自然" },
	{ name: "衛生福利部食品藥物管理署(食藥署)", title: "", key: "國小自然" },
	{ name: "經濟部水利署第十河川局", title: "", key: "國小自然" },
	{ name: "交通部觀光局(觀光局)", title: "", key: "國小自然" },
	{ name: "臺北市政府消防局防災科學教育館 (臺北市防災科學教育館)", title: "", key: "國小自然" },
	{ name: "臺南市政府觀光旅遊局(臺南市觀旅局)", title: "臺南印象", key: "國小自然" },
	{ name: "東森", title: "好好玩自然", key: "國中自然" },
	{ name: "公視", title: "下課花路米", key: "國中自然" },
	{ name: "大愛", title: "發現", key: "國中自然" },
	{ name: "大愛", title: "生活裡的科學", key: "國中自然" },
	{ name: "大愛", title: "紀錄新發現", key: "國中自然" },
	{ name: "大愛", title: "TRY科學", key: "國中自然" },
	{ name: "泛科學", title: "科學大爆炸", key: "國中自然" },
	{ name: "泛科學", title: "可能性調查署", key: "國中自然" },
	{ name: "LIS", title: "自然系列-物理 | 浮力", key: "國中自然" },
	{ name: "LIS", title: "自然系列-物理 | 光學", key: "國中自然" },
	{ name: "LIS", title: "自然系列-化學 | 物質探索", key: "國中自然" },
	{ name: "豪客", title: "", key: "國中自然" },
	{ name: "中視", title: "大陸尋奇", key: "國中自然" },
	{ name: "廖振順老師", title: "", key: "國中自然" },
	{ name: "NASA", title: "", key: "國中自然" },
	{ name: "許鐘綱先生", title: "", key: "國中自然" },
	{ name: "聖工坊", title: "", key: "國中自然" },
	{ name: "行政院農業委員會 (農委會)", title: "生態台灣系列影集", key: "國中自然" },
	{ name: "行政院環境保護署(環保署)", title: "", key: "國中自然" },
	{ name: "行政院農業委員會水土保持局(水保局)", title: "", key: "國中自然" },
	{ name: "交通部中央氣象局(氣象局)", title: "", key: "國中自然" },
	{ name: "公共電視", title: "", key: "國小社會" },
	{ name: "公共電視", title: "我們的島", key: "國小社會" },
	{ name: "公共電視", title: "青春發言人", key: "國小社會" },
	{ name: "公共電視", title: "下課花路米", key: "國小社會" },
	{ name: "臺灣吧", title: "", key: "國小社會" },
	{ name: "三立", title: "", key: "國小社會" },
	{ name: "泛科知識", title: "可能性調查署", key: "國小社會" },
	{ name: "新北消防發爾麵", title: "", key: "國小社會" },
	{ name: "教育部", title: "", key: "國小社會" },
	{ name: "果陀劇場", title: "", key: "國小社會" },
	{ name: "民視", title: "民視新聞", key: "國小社會" },
	{ name: "東森", title: "東森新聞", key: "國小社會" },
	{ name: "東森", title: "台灣1001個故事", key: "國小社會" },
	{ name: "中視", title: "中視新聞", key: "國小社會" },
	{ name: "中視", title: "MIT", key: "國小社會" },
	{ name: "華視", title: "華視新聞", key: "國小社會" },
	{ name: "華視", title: "華視新聞雜誌", key: "國小社會" },
	{ name: "原視", title: "原視新聞", key: "國小社會" },
	{ name: "國立科學工藝博物館", title: "", key: "國小社會" },
	{ name: "綠色和平組織", title: "", key: "國小社會" },
	{ name: "大愛", title: "", key: "國小社會" },
	{ name: "Money101", title: "", key: "國小社會" },
	{ name: "志祺七七 X 圖文不符", title: "", key: "國小社會" },
	{ name: "臺南市政府觀光旅遊局", title: "", key: "國小社會" },
	{ name: "豪客", title: "", key: "國小社會" },
	{ name: "聖工坊", title: "", key: "國小社會" },
	{ name: "中華文化永續發展基金會", title: "馮翊綱說人解字", key: "國小社會" },
	{ name: "新竹市環保局", title: "", key: "國小社會" },
	{ name: "衛生署疾病管制局", title: "", key: "國小社會" },
	{ name: "交通部道安委員會", title: "", key: "國小社會" },
	{ name: "內政部消防署", title: "", key: "國小社會" },
	{ name: "國立臺灣史前文化博物館", title: "", key: "國小社會" },
	{ name: "藺子", title: "", key: "國小社會" },
	{ name: "風傳媒", title: "", key: "國小社會" },
	{ name: "遠見", title: "", key: "國小社會" },
	{ name: "關鍵評論網", title: "", key: "國小社會" },
	{ name: "行政院農委會", title: "", key: "國小社會" },
	{ name: "cheap", title: "", key: "國小社會" },
	{ name: "台客劇場", title: "", key: "國小社會" },
	{ name: "國際青少年人權協會", title: "", key: "國小社會" },
	{ name: "華視", title: "華視新聞", key: "國中社會" },
	{ name: "原視", title: "", key: "國中社會" },
	{ name: "公視", title: "", key: "國中社會" },
	{ name: "公視", title: "我們的島", key: "國中社會" },
	{ name: "公視", title: "公視新聞", key: "國中社會" },
	{ name: "公視", title: "青春發言人", key: "國中社會" },
	{ name: "民視", title: "民視新聞", key: "國中社會" },
	{ name: "臺灣歷史博物館", title: "", key: "國中社會" },
	{ name: "東森", title: "抗戰：和平榮耀•勝利70", key: "國中社會" },
	{ name: "東森", title: "台灣1001個故事", key: "國中社會" },
	{ name: "One-Forty", title: "", key: "國中社會" },
	{ name: "一件襯衫：你揮灑的城市日常風景", title: "", key: "國中社會" },
	{ name: "關鍵評論網", title: "", key: "國中社會" },
	{ name: "臺灣吧", title: "", key: "國中社會" },
	{ name: "三立", title: "消失的國界", key: "國中社會" },
	{ name: "志祺七七 X 圖文不符", title: "", key: "國中社會" },
	{ name: "衛生福利部社會及家庭署", title: "", key: "國中社會" },
	{ name: "豪客", title: "", key: "國中社會" },
	{ name: "THE STORY OF STUFF、看守臺灣", title: "", key: "國中社會" },
	{ name: "經濟部智財局", title: "", key: "國中社會" },
	{ name: "中華民國紅十字會總會", title: "", key: "國中社會" },
	{ name: "國際青少年人權協會", title: "", key: "國中社會" },
	{ name: "TXL films", title: "", key: "國中社會" },
	{ name: "大愛", title: "全紀錄", key: "國中社會" },
	{ name: "教育部", title: "", key: "國中社會" },
	{ name: "教育部歷史文化學習網", title: "", key: "國中社會" },
	{ name: "監察院", title: "", key: "國中社會" },
	{ name: "大愛", title: "當我們童在一起", key: "國小生活" },
	{ name: "大愛", title: "飛天妙妙屋", key: "國小生活" },
	{ name: "大愛", title: "生活裡的科學", key: "國小生活" },
	{ name: "大愛", title: "大愛新聞", key: "國小生活" },
	{ name: "大愛", title: "小主播看天下WOW", key: "國小生活" },
	{ name: "大愛", title: "小人物大英雄", key: "國小生活" },
	{ name: "大愛", title: "蔬果生活誌", key: "國小生活" },
	{ name: "大愛", title: "呼叫妙博士", key: "國小生活" },
	{ name: "公視", title: "下課花路米", key: "國小生活" },
	{ name: "公視", title: "我們的島", key: "國小生活" },
	{ name: "東森", title: "東森新聞", key: "國小生活" },
	{ name: "GTV八大電視", title: "台灣第一等", key: "國小生活" },
	{ name: "momo親子台", title: "禮貌小寶貝", key: "國小生活" },
	{ name: "自由時報", title: "", key: "國小生活" },
	{ name: "財團法人聖嚴教育基金會", title: "心靈環保", key: "國小生活" },
	{ name: "衛生福利部疾病管制署", title: "", key: "國小生活" },
	{ name: "衛生福利部", title: "", key: "國小生活" },
	{ name: "行政院新聞傳播處", title: "", key: "國小生活" },
	{ name: "行政院環境保護署", title: "", key: "國小生活" },
	{ name: "行政院農業委員會", title: "", key: "國小生活" },
	{ name: "內政部警政署", title: "", key: "國小生活" },
	{ name: "臺北市政府警察局婦幼警察隊", title: "", key: "國小生活" },
	{ name: "麥當勞叔叔之家慈善基金會", title: "", key: "國小生活" },
	{ name: "灃食公益飲食文化教育基金會", title: "", key: "國小生活" },
	{ name: "伊甸基金會", title: "", key: "國小生活" },
	{ name: "靖娟基金會", title: "", key: "國小生活" },
	{ name: "愛種樹股份有限公司", title: "", key: "國小生活" },
	{ name: "紅樹林有線電視", title: "紅樹林新聞網", key: "國小生活" },
	{ name: "淡江新聞", title: "", key: "國小生活" },
	{ name: "shutterstock", title: "", key: "國小生活" },
	{ name: "行政院農業委員會", title: "", key: "國小數學" },
	{ name: "三立", title: "", key: "國小數學" },
];

export const AUTHORIZE_RANGE_TYPE = [
	{ name: "1.產品使用範圍：光碟產品，如電子書、DVD", value: "1.產品使用範圍：光碟產品，如電子書、DVD" },
	{ name: "2.產品使用範圍：OneBook", value: "2.產品使用範圍：OneBook" },
	{ name: "3.產品使用範圍：OneBox", value: "3.產品使用範圍：OneBox" },
	{ name: "4.產品使用範圍：OneStudy", value: "4.產品使用範圍：OneStudy" },
	{ name: "5.產品使用範圍：OneClass app", value: "5.產品使用範圍：OneClass app" },
	{ name: "6.產品使用範圍：南一影音網", value: "6.產品使用範圍：南一影音網" },
];
