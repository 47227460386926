import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { Select, Breadcrumb, Button, Table, AuthProvider, Modal, Upload } from "common-components";

import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { getBankSelection, getBankBookSelection, getBankQuestion } from "api/bank";
import { setSelectOptions, sliceTableData, search, blobDownload } from "utils/common";
import { Checkbox, Menu, Dropdown } from "antd";
import { API, initUpload, uploadFile, finishedUpload } from "api/uploadFile";
import { getTubeList } from "api/stored";
import { FILE_CHECK_STUTS } from "constants/index";
import { format } from "date-fns";
import {
	UiRuleSearchPage,
	UiWarp,
	UiButtons,
	UIDialogContent,
	UIEditorContent,
	UIDialogCheckFileList,
} from "./VideoUploadPage.style";
import { getSpecificationProduct } from "api/specification";
import { DialogSelect, EditorData, ReplaceFileList, Icons } from "components";

const { Item } = Table;
/**
 * 試題查詢頁面
 */

const breadcrumbList = [
	{
		icon: "unarchive",
		name: "影片入庫",
		path: "/videoUpload",
	},
];

const initSendFormData = {
	education: "",
	subject: "",
	version: "",
	year: "",
	books: "",
};

const defaultColumnData = [
	{
		id: "uid",
		code: "uid",
		value: "試題ID",
		isdefault: true,
	},
	{
		id: "descContent",
		code: "descContent",
		value: "試題內容",
		isdefault: true,
	},
	{
		id: "QUES_TYPE",
		code: "QUES_TYPE",
		value: "題型",
		isdefault: true,
	},
	{
		id: "KNOWLEDGE",
		code: "KNOWLEDGE",
		value: "知識向度",
		isdefault: true,
	},
	{
		id: "SOURCE",
		code: "SOURCE",
		value: "出處",
		isdefault: true,
	},
];

const dialogType = {
	SELECT_UPLOAD: "select_upload",
	LINK: "link",
	UPLOAD: "upload",
	DOWNLOAD: "download",
	EXCEL_UPLOAD: "excelUpload",
};

const { useAuthState } = AuthProvider;

export const VideoUploadPage = () => {
	const authState = useAuthState();
	const userColConfig = authState.context.result.preference.columnOptions || [];
	const tableRef = useRef(null);
	const [checkedList, setCheckedList] = useState([]); //["0-0-0"]

	const [sendFormData, setSendFormData] = useState(initSendFormData);
	const [treeActiveKey, setTreeActiveKey] = useState(null);
	const [quesIDList, setQuesIDList] = useState([]);

	const [questionData, setQuestionData] = useState([]);
	const [specList, setSpecList] = useState([]);
	const [uploadFinishBtn, setUploadFinishBtn] = useState(false);
	const [lockUploadBtn, setLockUploadBtn] = useState(false);
	const [files, setFiles] = useState([]);
	const [tempFiles, setTempFiles] = useState([]);
	const [uploadPopup, setUploadPopup] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFileCheckPopup, setIsFileCheckPopup] = useState(false);
	const [uploadMenu, setUploadMenu] = useState(false);
	const [uploadSubmit, setUploadSubmit] = useState(true);
	const [fileIds, setFileIds] = useState([]);
	const [uploadIds, setUploadIds] = useState([]);
	const [uploadSelectInfo, setUploadSelectInfo] = useState({});
	const [isDialogType, setIsDialogType] = useState(dialogType.UPLOAD);

	const [stateSelect, sendSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getBankSelection();
				console.log(result);
				const {
					data: { yearMap, eduMap, eduVersion, eduSubject, books },
				} = result;
				return {
					eduSubject: eduSubject || {},
					eduVersion: eduVersion || {},
					eduMap: eduMap ? setSelectOptions(eduMap) : [],
					yearMap: yearMap ? setSelectOptions(yearMap) : [],
					books: books || {},
				};
			},
		},
	});

	const [stateSpecificationProduct, sendSpecificationProduct] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const {
					data: {
						specList,
						selection: { bookMap },
					},
				} = await getSpecificationProduct(event.year);

				console.log(bookMap);
				const nextSpecList = specList.map((item) => {
					const bookList = item.books.map((book, index, arr) => {
						let bookName = "";
						bookMap.forEach((item) => {
							if (item.code === book) bookName = item.name;
						});
						return <span key={index}>{`${bookName}${index !== arr.length - 1 ? " | " : ""}`}</span>;
					});

					//貌似可以把值reset
					return {
						...item,
						bookList,
					};
				});
				return {
					bookMap: setSelectOptions(bookMap || []),
				};
			},
		},
	});

	const { bookMap } = stateSpecificationProduct.context.result || {};

	//搜尋
	const [stateBookSelect, sendBookSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getBankBookSelection(event.payload);
				console.log(result);
				const {
					data: { bookMap },
				} = result;
				return {
					bookMap,
				};
			},
		},
	});

	const { eduSubject, eduVersion, eduMap, yearMap } = stateSelect.context.result || {};

	const eduVersionOption = useMemo(() => {
		console.log("eduVersion", eduVersion);
		if (!sendFormData.education || !eduVersion) return [];
		return setSelectOptions(eduVersion[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const eduSubjectOption = useMemo(() => {
		if (!sendFormData.education || !eduSubject) return [];
		return setSelectOptions(eduSubject[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const formatTableColData = (list, defaultData = {}) => {
		let nextData = defaultData;
		list.forEach((item) => {
			nextData = {
				...nextData,
				[item.code]: {
					name: item.value,
					defaultValue: "--",
				},
			};
		});
		return nextData;
	};

	const onSelectChange = (key, value) => {
		console.log("sendFormData", sendFormData);
		setSendFormData({
			...sendFormData,
			[key]: value,
		});
		sendSpecificationProduct(FetchEventType.Fetch, sendFormData);
	};

	const searchBookMap = useCallback(() => {
		getTubeList(sendFormData);
		if (Object.entries(sendFormData).findIndex(([, val]) => val === "") !== -1) return;

		// setTreeActiveKey(null);
		// setCheckedList([]);
		// setQuestionData([]);
		// setQuesIDList([]);
		// sendBookSelect(FetchEventType.Fetch, { payload: sendFormData });
		// console.log("sendFormData", sendFormData);
		// sendSpecificationProduct(FetchEventType.Fetch, sendFormData);
	}, [sendFormData]);

	useEffect(() => {
		const defaultColumnConfig = userColConfig.length > 0 ? userColConfig : defaultColumnData;
		const nextData = formatTableColData(defaultColumnConfig);
		sendSelect(FetchEventType.Fetch);
		sendSpecificationProduct(FetchEventType.Fetch, { year: "109" });
		// setList(defaultColumnConfig);
		// setTableColumn(nextData);
	}, []);

	useEffect(() => {
		if (eduVersionOption.length === 0) return;
		setSendFormData({
			...sendFormData,
			version: eduVersionOption[0].value,
		});
	}, [eduVersionOption]);

	useEffect(() => {
		if (eduSubjectOption.length === 0 || !sendFormData.version) return;
		setSendFormData({
			...sendFormData,
			subject: eduSubjectOption[0].value,
		});
	}, [sendFormData.version, eduSubjectOption]);

	useEffect(() => {
		if (stateSelect.matches(FetchStates.Resolved)) {
			setSendFormData({
				...sendFormData,
				year: yearMap[0].value,
				education: eduMap[0].value,
			});
		}
	}, [stateSelect.value]);

	//---------上傳-----------
	const toggleUploadPopupVisible = (type) => {
		setIsDialogType(type);
		setUploadPopup(true);
		setUploadMenu(false);
		setFiles([]);
	};

	const closeDialog = () => {
		console.log(123);
		setUploadPopup(false);
		setUploadSubmit(true);
		setTempFiles([]);
	};

	const submitFiles = useCallback(async () => {
		if (files.length === 0) return;
		console.log(files);
		setUploadSubmit(false);
		setLockUploadBtn(true);

		const fileNames = files.map((info) => {
			return info.name;
		});

		const {
			year,
			education,
			subject,
			version,
			books,
			itemType,
			type,
			studyType,
			knowledgeType,
			authorizeType,
		} = uploadSelectInfo;
		const data = {
			uploadOption: {
				schoolYear: year,
				edu: education,
				subject,
				version,
				book: books,
				itemType,
				type: "type",
				learnResource: studyType,
				knowledge: knowledgeType,
				authorizedScope: authorizeType,
				force: true,
			},
			fileNames: fileNames,
		};

		console.log(data);
		const res = await API.post(`${process.env.REACT_APP_ONETUBE_API}/api/Stored/CheckFile`, data);
		console.log("res", res);
		if (res.data && res.data.length > 0) {
			files.map((info) => {
				const file = res.data.find((d) => {
					return d.fileName === info.name;
				});
				if (file) {
					info.fileId = file.fileId;
				}
			});
			let finishCount = 0;
			const fileArr = await files.filter(async (file) => {
				file.progress = 0;
				const res = await initUpload(file.fileId);
				console.log(res);
				file.fileIds = res;
				const upload = await uploadFile(file, file.fileId, res, setFiles, files);
				console.log(upload);
				setUploadIds(upload);
				const uploadComplete = await finishedUpload(file.fileId, res, upload);
				console.log(uploadComplete);
				if (uploadComplete.message === "Success") {
					file.uploadComplete = file;
				} else {
					file.uploadComplete = "Error";
				}
				finishCount++;
				if (finishCount === files.length) {
					setUploadFinishBtn(true);
				}
			});
			console.log(fileArr);
		}
	}, [files, uploadSelectInfo]);

	const changeFileList = useCallback(
		async (value) => {
			const newFiles = [];
			const tempFile = tempFiles;

			setLockUploadBtn(false);
			console.log("files", files);
			console.log("value", value);

			let newFile = value.filter((info) => {
				let check = null;
				files.map((file) => {
					if (info.name === file.name && info.uid !== file.uid) {
						check = file;
					}
				});
				console.log(check);
				if (check) {
					info.fileCheckStuts = FILE_CHECK_STUTS.SAME_FILE_NAME;
					tempFile.push(info);
				} else {
					return info;
				}
			});

			const fileNames = newFile.map((info) => {
				return info.name;
			});
			console.log("tempFile", tempFile);
			console.log("uploadSelectInfo", uploadSelectInfo);
			console.log(fileNames);
			if (fileNames.length <= 0) return;
			const {
				year,
				education,
				subject,
				version,
				books,
				itemType,
				type,
				studyType,
				knowledgeType,
				authorizeType,
			} = uploadSelectInfo;
			const data = {
				uploadOption: {
					schoolYear: year,
					edu: education,
					subject,
					version,
					book: books,
					itemType,
					type: "type",
					learnResource: studyType,
					knowledge: knowledgeType,
					authorizedScope: authorizeType,
				},
				fileNames: fileNames,
			};
			console.log(data);
			const res = await API.post(`${process.env.REACT_APP_ONETUBE_API}/api/Stored/CheckFile`, data);

			console.log("res", res);
			if (res.data && res.data.length > 0) {
				newFile = newFile.filter((info) => {
					const file = res.data.find((d) => {
						console.log(d, info);
						return d.fileName === info.name;
					});
					console.log();
					if (file) {
						if (file.isOK) {
							info.fileId = file.fileId;
							info.fileName = file.fileName;
							info.isOK = file.isOK;
							return info;
						} else {
							if (!info.fileCheckStuts) {
								info.fileCheckStuts = file.errorMessage;
								tempFile.push(info);
							}
						}
					}
				});
			}

			console.log(res);

			console.log("newFile", newFile);

			setTempFiles(tempFile);
			setFiles(newFile ? newFile : []);
			console.log("tempFiles", tempFiles);

			// const { year, education, subject, version, books, itemType, type, studyType, knowledgeType, authorizeType } = uploadSelectInfo;
			// const data = {
			// 	uploadOption: {
			// 		schoolYear: year,
			// 		edu: education,
			// 		subject,
			// 		version,
			// 		book: books,
			// 		itemType,
			// 		type: "type",
			// 		learnResource: studyType,
			// 		knowledge: knowledgeType,
			// 		authorizedScope: authorizeType,
			// 	},
			// 	fileNames: fileNames,
			// };
			// console.log(data);
			// setTempFiles(value);

			// const res = await API.post(`${process.env.REACT_APP_ONETUBE_API}/api/Stored/CheckFile`, data);
			// if (res && res.data.length > 0) {
			// 	const replaceFiles = [];
			// 	const fileIds = [];
			// 	const fileArr = value.filter(async (file) => {
			// 		const find = res.data.find((info) => file.name === info.fileName);
			// 		console.log(find, file);
			// 		if (find) {
			// 			const res = await initUpload(find.fileId);
			// 			console.log(res);
			// 			file.fileIds = res;
			// 			const upload = await uploadFile(file, find.fileId, res);
			// 			console.log(upload);
			// 			setUploadIds(upload);
			// 			const uploadComplete = await finishedUpload(find.fileId, res, upload);
			// 			console.log(uploadComplete);
			// 			return file;
			// 		} else {
			// 			replaceFiles.push(file);
			// 		}
			// 	});
			// 	console.log(fileArr);
			// 	console.log(replaceFiles);
			// 	setFiles(fileArr);
			// }
			// setFiles([...files, ...value]);
			// console.log("res", res);
		},
		[uploadSelectInfo, files, tempFiles],
	);

	const uploadLinkDialogOk = () => {
		setUploadPopup(false);
	};

	const fileCheckPopupOk = () => {
		setIsFileCheckPopup(false);
	};

	const replaceFile = useCallback(
		(file) => {
			const fileArr = files.filter((info) => {
				if (info.name === file.name) {
					return file;
				} else {
					return info;
				}
			});
			if (files.length <= 0 || file.fileCheckStuts !== FILE_CHECK_STUTS.SAME_FILE_NAME) {
				fileArr.push(file);
			}

			const tempFileArr = tempFiles.filter((info) => info.uid !== file.uid);
			setTempFiles(tempFileArr);
			setFiles(fileArr);
		},
		[files, tempFiles],
	);
	const cancelFile = useCallback(
		(file) => {
			const fileArr = tempFiles.filter((info) => info.uid !== file.uid);
			setTempFiles(fileArr);
		},
		[tempFiles],
	);

	const uploadDialog = () => {
		return (
			<UIDialogContent>
				<Modal
					visible={uploadPopup}
					onCancel={closeDialog}
					onOk={uploadLinkDialogOk}
					title="上傳試題"
					width={"800"}
					footer={
						<UiButtons>
							{!uploadFinishBtn && (
								<Button variant="white" full={false} onClick={closeDialog} Key="cancel" loading={isLoading}>
									取消
								</Button>
							)}

							{uploadFinishBtn ? (
								<Button variant="waterBlue" full={false} onClick={() => {}} Key="ok">
									確定
								</Button>
							) : (
								<Button variant="waterBlue" full={false} onClick={submitFiles} Key="ok" disabled={lockUploadBtn}>
									開始上傳
								</Button>
							)}
						</UiButtons>
					}>
					<DialogSelect setUploadSelectInfo={setUploadSelectInfo} />

					<div>上傳檔案</div>
					{uploadSubmit ? (
						<Upload
							fileList={files}
							multiple={true}
							onChange={changeFileList}
							prompt={<>僅限上傳 10 個影片檔（副檔名：.mp4）</>}
							acceptFileType=".mp4"
						/>
					) : (
						<div className="progressList">
							<div className="title">影片上傳時，請勿關閉此頁</div>
							<div>
								{files.map((info, index) => {
									return (
										<div className="list" key={index}>
											<div className="name">
												<div className="icon">{Icons.Paperclip}</div>
												<div>{info.name}</div>
											</div>
											{info.progress < 100 ? (
												<div className="progress">
													<div className="line">
														<div className="down"></div>
														<div style={{ width: `${info.progress}%` }} className="up"></div>
													</div>
													<div className="number">{`${info.progress}%`}</div>
												</div>
											) : (
												<div className="complete">
													<div className="icon">
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																d="M7.99967 1.3335C4.31967 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31967 14.6668 7.99967 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99967 1.3335ZM6.66634 11.3335L3.33301 8.00016L4.27301 7.06016L6.66634 9.44683L11.7263 4.38683L12.6663 5.3335L6.66634 11.3335Z"
																fill="#52C41A"
															/>
														</svg>
													</div>
													<div>上傳完成</div>
												</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					)}
					{tempFiles.length > 0 && (
						<ReplaceFileList files={tempFiles} replaceFile={replaceFile} cancelFile={cancelFile} />
					)}
				</Modal>
			</UIDialogContent>
		);
	};

	const uploadLinkDialog = () => {
		return (
			<UIDialogContent>
				<Modal
					visible={uploadPopup}
					onCancel={closeDialog}
					onOk={uploadLinkDialogOk}
					title="上傳試題"
					footer={
						<UiButtons>
							<Button variant="white" full={false} onClick={closeDialog} Key="cancel" loading={isLoading}>
								取消
							</Button>
							<Button
								variant="orange"
								full={false}
								onClick={uploadLinkDialogOk}
								Key="ok"
								loading={isLoading}
								disabled={files.length === 0}>
								確認
							</Button>
						</UiButtons>
					}>
					<Upload
						fileList={files}
						multiple={true}
						onChange={changeFileList}
						prompt={
							<>
								僅限上傳20個Microsoft Word 檔<br />
								(副檔名 : .doc/.docx)
							</>
						}
						acceptFileType=".doc,.docx"
					/>
				</Modal>
			</UIDialogContent>
		);
	};

	const editorDialog = () => {
		return (
			<UIEditorContent>
				<Modal
					style={{ color: "#fff" }}
					visible={uploadPopup}
					onCancel={closeDialog}
					title="修改資料"
					footer={
						<UiButtons>
							<Button variant="white" full={false} onClick={closeDialog} Key="cancel" loading={isLoading}>
								取消
							</Button>
							<Button variant="orange" full={false} Key="ok" loading={isLoading} disabled={files.length === 0}>
								確認
							</Button>
						</UiButtons>
					}>
					<div className="selectUploadBtns">
						<div className="btns">
							<EditorData />
						</div>
					</div>
				</Modal>
			</UIEditorContent>
		);
	};

	const fileCheckPopup = (isFileCheckPopup) => {
		return (
			<UIDialogCheckFileList>
				<Modal
					visible={isFileCheckPopup}
					onCancel={() => fileCheckPopupOk()}
					onOk={() => fileCheckPopupOk()}
					title={`取代檔案 (共 筆)`}
					width={"500"}
					footer={<div></div>}>
					<div className="selectUploadBtns">
						<div className="content">
							<div>影片待入庫中已有同名的檔案，是否要取代？</div>
							<div className="lists">
								<div>
									<div>影片名稱影片名稱.mp4</div>
									<div>同名檔案上次上傳時間</div>
								</div>
								<div className="btns">
									<Button variant="blue" full={false} Key="cancel" loading={isLoading}>
										略過
									</Button>
									<Button variant="blue" full={false} loading={isLoading}>
										取代
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</UIDialogCheckFileList>
		);
	};

	const showDialog = (type) => {
		console.log(type);
		switch (type) {
			case dialogType.UPLOAD:
				return uploadDialog();
			case dialogType.LINK:
				return uploadLinkDialog();
			case dialogType.EDITOR:
				return editorDialog();
			default:
				return;
		}
	};
	useEffect(() => {
		setSpecList([
			{
				select: false,
				name: "XXX",
				year: "109",
				edu_sub: "國小國語",
				version: "南一",
				bookList: ["第一冊", "第二冊"],
				status: "待確認",
				time: "2021/10/22",
				uploader: "horryt",
			},
		]);
	}, [setSpecList]);

	const menu = (
		<Menu>
			<Menu.Item>
				<div onClick={() => toggleUploadPopupVisible(dialogType.UPLOAD)}>上傳實體影片</div>
			</Menu.Item>
			<Menu.Item>
				<div onClick={() => toggleUploadPopupVisible(dialogType.LINK)}>上傳影片連結</div>
			</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<UiRuleSearchPage>
				<div className="breadBlock">
					<Breadcrumb data={breadcrumbList} />
				</div>
				<div className="searchToolBar">
					<div className="selectSearchGroup">
						<Select
							placeholder="請選擇學年度"
							options={yearMap}
							value={sendFormData.year}
							onChange={(val) => {
								onSelectChange("year", val);
							}}
						/>
						<Select
							placeholder="請選擇學制"
							options={eduMap}
							value={sendFormData.education}
							onChange={(val) => {
								onSelectChange("education", val);
							}}
						/>
						{eduSubjectOption.length > 0 ? (
							<Select
								placeholder="請選擇科目"
								options={eduSubjectOption}
								value={sendFormData.subject}
								onChange={(val) => {
									onSelectChange("subject", val);
								}}
							/>
						) : (
							""
						)}
						{eduVersionOption.length > 0 ? (
							<Select
								placeholder="請選擇版本"
								options={eduVersionOption}
								value={sendFormData.version}
								onChange={(val) => {
									onSelectChange("version", val);
								}}
							/>
						) : (
							""
						)}
						{bookMap && bookMap.length > 0 ? (
							<Select placeholder="冊次" options={bookMap} onChange={(val) => onSelectChange("books", val)} />
						) : (
							""
						)}

						<Button.IconButton
							variant="blue"
							full={false}
							icon={
								<i className="material-icons" style={{ fontSize: "20px" }}>
									search
								</i>
							}
							onClick={searchBookMap}>
							搜尋
						</Button.IconButton>
					</div>
				</div>
				<div className="flexEndBox">
					<Dropdown overlay={menu} visible={uploadMenu}>
						<Button.IconButton
							variant="blue"
							full={false}
							iconName="unarchive"
							onClick={(e) => setUploadMenu(!uploadMenu)}>
							上傳影片
						</Button.IconButton>
					</Dropdown>

					<Button.IconButton
						variant="blue"
						full={false}
						iconName="download"
						onClick={() => toggleUploadPopupVisible(dialogType.DOWNLOAD)}>
						下載清單
					</Button.IconButton>
					<Button.IconButton
						variant="blue"
						full={false}
						iconName=""
						onClick={() => toggleUploadPopupVisible(dialogType.EXCEL_UPLOAD)}>
						上傳清單
					</Button.IconButton>
				</div>
				<div className="tableBlock">
					<Table>
						<Table.Header>
							<Table.Row>
								<Item flex="0.5">勾選</Item>
								<Item>檔案名稱</Item>
								<Item>學年度</Item>
								<Item>學制科目</Item>
								<Item>版本</Item>
								<Item>冊次</Item>
								<Item>檔案狀態</Item>
								<Item>上傳時間</Item>
								<Item>上傳者</Item>
								<Item>操作</Item>
								<Item></Item>
								<Item></Item>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{specList &&
								specList.map((item, index) => {
									return (
										<Table.Row key={index}>
											<Table.Item>
												<Checkbox onChange={(event) => {}} />
											</Table.Item>
											<Table.Item>{item.name}</Table.Item>
											<Table.Item>{item.year}</Table.Item>
											<Table.Item>
												<span className="subjectItem">{`${item.edu_sub}`}</span>
											</Table.Item>
											<Table.Item>{item.version}</Table.Item>
											<Table.Item>
												<UiWarp>{item.bookList}</UiWarp>
											</Table.Item>
											{/* <Table.Item>{format(new Date(item.version), "yyyy/MM/dd HH:mm")}</Table.Item> */}

											<Table.Item>{item.status}</Table.Item>
											<Table.Item>{item.time}</Table.Item>
											<Table.Item>{item.uploader}</Table.Item>
											<Table.Item flex="2">
												<Button.IconButton variant="green2" iconName="" onClick={() => {}}>
													觀看影片
												</Button.IconButton>
											</Table.Item>
											<Table.Item flex="2">
												<Button.IconButton iconName="" onClick={() => toggleUploadPopupVisible(dialogType.EDITOR)}>
													修改資料
												</Button.IconButton>
											</Table.Item>
											<Table.Item>
												<Button.IconButton iconName="" onClick={() => {}}>
													刪除
												</Button.IconButton>
											</Table.Item>
										</Table.Row>
									);
								})}
						</Table.Body>
					</Table>
				</div>
			</UiRuleSearchPage>
			{showDialog(isDialogType)}
			{fileCheckPopup(isFileCheckPopup)}
		</div>
	);
};
