const cookieLoginName = "nani_oneclass_resource_platform_token";
function getNaniOneClassCookie(name) {
	const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
	return v ? v[2] : null;
}

export const API = {
	get: (url, data, token) => {
		const headers = {
			Authorization: getNaniOneClassCookie(cookieLoginName),
		};
		return fetch(url, {
			method: "GET",
			headers,
		})
			.then((response) => response.json())
			.catch((err) => console.error(err));
	},
	post: (url, data, token) => {
		const body = JSON.stringify(data);
		const headers = {
			Authorization: getNaniOneClassCookie(cookieLoginName),
			"Content-Type": "application/json",
		};
		return fetch(url, {
			method: "POST",
			headers,
			body,
		})
			.then((response) => response.json())
			.catch((err) => console.error(err));
	},
};

export const onFileSendClick = async () => {
	const file = this.myFile;
	console.log(file);
	let fileId = await this.checkFile(file.name);
	console.log(fileId);
	let uploadId = await this.initUpload(fileId);
	console.log(uploadId);
	let results = await this.uploadFile(file, fileId, uploadId);
	await this.finishedUpload(fileId, uploadId, results);
};

export const checkFile = async (filename) => {
	var checkFileRequest = {
		uploadOption: {
			schoolYear: "110",
			edu: "E",
			subject: "MA",
			version: "N",
			book: "B01",
		},
		fileNames: [filename],
	};
	var res = await fetch(`${process.env.REACT_APP_ONETUBE_API}/api/Stored/CheckFile`, {
		body: JSON.stringify(checkFileRequest),
		headers: {
			Authorization: getNaniOneClassCookie(cookieLoginName),
			"content-type": "application/json",
		},
		method: "POST",
	}).then((response) => response.json());

	return res.data[0].fileId;
};

export const initUpload = async (fileId) => {
	var initUploadRequest = {
		fileId: fileId,
	};
	var res = await fetch(`${process.env.REACT_APP_ONETUBE_API}/api/Stored/Upload/Init`, {
		body: JSON.stringify(initUploadRequest),
		headers: {
			Authorization: getNaniOneClassCookie(cookieLoginName),
			"content-type": "application/json",
		},
		method: "POST",
	}).then((response) => response.json());
	return res.data.uploadId;
};

export const uploadFile = async (file, fileId, uploadId, setFiles, files) => {
	console.log("file", file);
	console.log("fileId", fileId);
	console.log("uploadId", uploadId);
	const chunkSize = 10 * 1024 * 1024; //10MB

	const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

	const blockCount = Math.ceil(file.size / chunkSize);
	const resultMap = [];
	for (let i = 0; i < blockCount; i++) {
		const start = i * chunkSize;
		const end = Math.min(file.size, start + chunkSize);
		// Building forms
		let data = blobSlice.call(file, start, end);
		const form = new FormData();
		form.append("file", data);
		form.append("fileId", fileId);
		form.append("partSize", blockCount);
		form.append("partNumber", i + 1);
		form.append("uploadId", uploadId);
		const headers = {
			Authorization: getNaniOneClassCookie(cookieLoginName),
		};
		const obj = {
			method: "POST",
			headers,
			body: form,
		};
		var res = await fetch(`${process.env.REACT_APP_ONETUBE_API}/api/Stored/Upload`, obj).then((response) =>
			response.json(),
		);
		console.log(i, blockCount);
		file.progress = (i / blockCount) * 100;
		setFiles(
			files.filter((info) => {
				if (info.uid === files.uid) {
					return files;
				} else {
					return info;
				}
			}),
		);
		console.log(file.progress);
		resultMap.push(res.data);
		console.log(res);
	}

	file.progress = 100;
	setFiles(
		files.filter((info) => {
			if (info.uid === files.uid) {
				return files;
			} else {
				return info;
			}
		}),
	);
	return resultMap;
};

export const finishedUpload = async (fileId, uploadId, results) => {
	var finishedFileRequest = {
		fileId: fileId,
		uploadId: uploadId,
		partResults: results,
	};
	var res = await fetch(`${process.env.REACT_APP_ONETUBE_API}/api/Stored/Upload/Complete`, {
		body: JSON.stringify(finishedFileRequest), // must match 'Content-Type' header
		headers: {
			Authorization: getNaniOneClassCookie(cookieLoginName),
			"content-type": "application/json",
		},
		method: "POST", // *GET, POST, PUT, DELETE, etc.
	}).then((response) => response.json());
	console.log(res);
	return res;
};
