import styled from "styled-components";

export const UIReplaceFileList = styled.div`
	padding: 24px 0px 50px;
	color: #000000d9;
	.count {
		display: flex;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		color: rgba(0, 0, 0, 0.85);

		> .icon {
			margin-right: 10px;
		}
	}
`;

export const UIList = styled.div`
	display: flex;
`;

export const UITitleText = styled.div`
	> .yellow {
		color: #faad14;
	}
	> .red {
		color: #ff4d4f;
	}
`;

export const UIbtns = styled.div`
	display: flex;
	> .cancel {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 5px 16px;

		position: static;
		left: 0%;
		right: 0%;
		top: 0%;
		bottom: 0%;

		/* circumstantial/pop-over */

		background: #ffffff;
		border: 1px solid #98989e;
		box-sizing: border-box;
		/* drop-shadow/button-secondary */

		box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
		border-radius: 2px;
		margin-right: 10px;
		color: rgba(83, 83, 83, 0.85);
	}
	> .replace {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 5px 16px;

		position: static;
		left: 68px;
		top: 0px;

		/* Daybreak Blue/6 */

		background: #ffffff;
		/* Daybreak Blue/6 */

		border: 1px solid #1890ff;
		box-sizing: border-box;
		/* drop-shadow/button-primary */

		box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
		border-radius: 2px;
		color: #1890ff;
	}
`;

export const UIListContent = styled.div`
	padding: 24px 24px 24px;
	color: #000000d9;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 15px;
`;

export const UIDuplicate = styled.div`
	padding: 24px;

	background: #ffffff;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
	border-radius: 4px;

	/* Inside Auto Layout */

	flex: none;
	order: 2;
	flex-grow: 0;
	margin: 24px 0px;
	.count {
		display: flex;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		color: rgba(0, 0, 0, 0.85);

		> .icon {
			margin-right: 10px;
		}
	}

	> .replaceContent {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		> .replace {
			width: 70px;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 5px 16px;

			position: static;
			left: 68px;
			top: 0px;

			/* Daybreak Blue/6 */

			background: #1890ff;
			/* Daybreak Blue/6 */

			border: 1px solid #1890ff;
			box-sizing: border-box;
			/* drop-shadow/button-primary */

			box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
			border-radius: 2px;
			color: #ffffff;
		}
	}
`;

export const UIDescription = styled.div`
	display: flex;
	> .yellow {
		color: #faad14;
		filter: hue-rotate(360deg);
	}
	> .red {
		color: #ff4d4f;
	}
	> .icon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
	}
`;
