import React, { useState } from "react";

import { List, message, Avatar, Spin } from "antd";
import { UIReplaceFileList, UITitleText, UIbtns, UIList, UIDescription } from "./ReplaceFileList.style";
import { FILE_CHECK_STUTS } from "constants/index";

export const ReplaceFileList = ({ files, replaceFile, cancelFile }) => {
	console.log("ReplaceFileList", files);

	const getErrorMessage = (value) => {
		console.log(value);
		switch (value) {
			case FILE_CHECK_STUTS.SAVED_CONFLICT:
				return (
					<UITitleText className="red">
						{"影音庫中已有同名的檔案，請至影音庫中停止此支影片的權限，才可上傳同名檔案"}
					</UITitleText>
				);
			case FILE_CHECK_STUTS.UNSAVED_CONFLICT:
				return <UITitleText className="yellow">{"影片待入庫中已有同名的檔案"}</UITitleText>;
			case FILE_CHECK_STUTS.SAME_FILE_NAME:
				return <UITitleText className="red">{"此次上傳影片中已有同名的檔案"}</UITitleText>;
			default:
				break;
		}
	};

	const cancel = (item) => {
		cancelFile(item);
	};
	const replace = (item) => {
		replaceFile(item);
	};
	return (
		<UIReplaceFileList>
			<div>未成功選擇檔案</div>
			<div className="listContent">
				<List
					itemLayout="horizontal"
					dataSource={files.map((info) => {
						return {
							title: (
								<UIList>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M12.3333 10.6663H4.66667C3.19333 10.6663 2 9.47301 2 7.99967C2 6.52634 3.19333 5.33301 4.66667 5.33301H13C13.92 5.33301 14.6667 6.07967 14.6667 6.99967C14.6667 7.91967 13.92 8.66634 13 8.66634H6C5.63333 8.66634 5.33333 8.36634 5.33333 7.99967C5.33333 7.63301 5.63333 7.33301 6 7.33301H12.3333V6.33301H6C5.08 6.33301 4.33333 7.07967 4.33333 7.99967C4.33333 8.91967 5.08 9.66634 6 9.66634H13C14.4733 9.66634 15.6667 8.47301 15.6667 6.99967C15.6667 5.52634 14.4733 4.33301 13 4.33301H4.66667C2.64 4.33301 1 5.97301 1 7.99967C1 10.0263 2.64 11.6663 4.66667 11.6663H12.3333V10.6663Z"
											fill="#98989E"
										/>
									</svg>
									<div>{info.name}</div>
								</UIList>
							),
							info,
						};
					})}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta
								title={item.title}
								description={
									<UIDescription>
										<div className="icon">
											{item.fileCheckStuts !== FILE_CHECK_STUTS.UNSAVED_CONFLICT ? (
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M7.3335 9.99967H8.66683V11.333H7.3335V9.99967ZM7.3335 4.66634H8.66683V8.66634H7.3335V4.66634ZM7.9935 1.33301C4.3135 1.33301 1.3335 4.31967 1.3335 7.99967C1.3335 11.6797 4.3135 14.6663 7.9935 14.6663C11.6802 14.6663 14.6668 11.6797 14.6668 7.99967C14.6668 4.31967 11.6802 1.33301 7.9935 1.33301ZM8.00016 13.333C5.0535 13.333 2.66683 10.9463 2.66683 7.99967C2.66683 5.05301 5.0535 2.66634 8.00016 2.66634C10.9468 2.66634 13.3335 5.05301 13.3335 7.99967C13.3335 10.9463 10.9468 13.333 8.00016 13.333Z"
														fill="#FAAD14"
													/>
												</svg>
											) : (
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M8.00016 1.33301C4.3135 1.33301 1.3335 4.31301 1.3335 7.99967C1.3335 11.6863 4.3135 14.6663 8.00016 14.6663C11.6868 14.6663 14.6668 11.6863 14.6668 7.99967C14.6668 4.31301 11.6868 1.33301 8.00016 1.33301ZM8.00016 13.333C5.06016 13.333 2.66683 10.9397 2.66683 7.99967C2.66683 5.05967 5.06016 2.66634 8.00016 2.66634C10.9402 2.66634 13.3335 5.05967 13.3335 7.99967C13.3335 10.9397 10.9402 13.333 8.00016 13.333ZM10.3935 4.66634L8.00016 7.05967L5.60683 4.66634L4.66683 5.60634L7.06016 7.99967L4.66683 10.393L5.60683 11.333L8.00016 8.93967L10.3935 11.333L11.3335 10.393L8.94016 7.99967L11.3335 5.60634L10.3935 4.66634Z"
														fill="#FF4D4F"
													/>
												</svg>
											)}
										</div>
										{getErrorMessage(item.info.fileCheckStuts)}
									</UIDescription>
								}
							/>
							<UIbtns>
								<div className="cancel" onClick={() => cancel(item.info)}>
									略過
								</div>
								<div className="replace" onClick={() => replace(item.info)}>
									取代
								</div>
							</UIbtns>
						</List.Item>
					)}
				/>
			</div>
		</UIReplaceFileList>
	);
};

ReplaceFileList.propTypes = {};
