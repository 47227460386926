import React, { Component } from "react";

export const Icons = {
	Paperclip: (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.3333 10.6668H4.66667C3.19333 10.6668 2 9.4735 2 8.00016C2 6.52683 3.19333 5.3335 4.66667 5.3335H13C13.92 5.3335 14.6667 6.08016 14.6667 7.00016C14.6667 7.92016 13.92 8.66683 13 8.66683H6C5.63333 8.66683 5.33333 8.36683 5.33333 8.00016C5.33333 7.6335 5.63333 7.3335 6 7.3335H12.3333V6.3335H6C5.08 6.3335 4.33333 7.08016 4.33333 8.00016C4.33333 8.92016 5.08 9.66683 6 9.66683H13C14.4733 9.66683 15.6667 8.4735 15.6667 7.00016C15.6667 5.52683 14.4733 4.3335 13 4.3335H4.66667C2.64 4.3335 1 5.9735 1 8.00016C1 10.0268 2.64 11.6668 4.66667 11.6668H12.3333V10.6668Z"
				fill="#98989E"
			/>
		</svg>
	),
};
