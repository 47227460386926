import styled from "styled-components";

export const UiRuleSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.filterBlock {
		flex: 0 0 36px;
	}
	.flexEndBox {
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
		> * {
			margin-right: 8px;
		}
	}
	.searchToolBar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			> * {
				margin-right: 8px;
			}
		}
	}

	.tableBlock {
		overflow-y: auto;
		margin-top: 24px;
		height: 100%;

		.eduItem {
			color: #ceff8f;
		}

		.subjectItem {
			color: #fff48e;
		}
	}

	.pageBlock {
		margin-top: 25px;
	}

	.selectUploadBtns {
		display: flex;
		justify-content: center;

		overflow: auto;
		height: 100%;
		.btns {
			display: flex;
			> * {
				margin-right: 8px;
			}
		}
	}

	.ant-modal-title {
		//color: #000;
	}
	.ant-modal-wrap {
		overflow: hidden;
	}

	.ant-modal-body {
		height: 71%;
	}
`;

export const UIDialogCheckFileList = styled.span`
	.selectUploadBtns {
		justify-content: center;

		overflow: auto;
		height: 100%;
		.content {
			> * {
				margin-right: 8px;
			}
			> .lists {
				display: flex;
				> .btns {
					display: flex;
				}
			}
		}
	}
	.ant-modal-header {
		border-bottom: none;
	}
`;

export const UIDialogContent = styled.span`
	.selectUploadBtns {
		display: flex;
		justify-content: center;

		overflow: auto;
		height: 100%;
		.btns {
			display: flex;
			> * {
				margin-right: 8px;
			}
		}
	}
	.progressList {
		padding: 10px;
		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 5px;
		}

		.title {
			color: #ff4d4f;
		}
		.list {
			display: flex;
			width: 100%;
			color: #98989e;
			.name {
				width: 75%;
				display: flex;
			}
			.progress {
				width: 20%;
				display: flex;
				justify-content: flex-end;
				.line {
					width: 65%;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 10px;
					.down {
						width: 100%;
						height: 5px;
						background: #d6d6d6;
						border-radius: 1px;
						position: absolute;
					}
					.up {
						height: 5px;
						background: #1890ff;
						border-radius: 1px;
						position: absolute;
						left: 0px;
					}
				}
				.number {
					width: 35%;
				}
			}
			.complete {
				color: #52c41a;
				display: flex;
			}
		}
	}
	.ant-modal-title {
		//color: #000;
	}
`;

export const UIEditorContent = styled.span`
	.ant-modal {
		top: 5%;
		height: 100%;
		padding-bottom: 5%;
	}
	.ant-modal-title {
		color: #000;
	}
	.ant-modal-content {
		height: 100%;
	}
	.ant-modal-wrap {
		overflow: hidden;
	}
	.ant-modal-body {
		height: 84%;
		overflow: auto;
	}
`;

export const UiWarp = styled.span`
	flex-wrap: wrap;
`;

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;
