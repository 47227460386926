import styled from "styled-components";

export const UiRuleSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.filterBlock {
		flex: 0 0 36px;
	}
	.flexEndBox {
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
		> * {
			margin-right: 8px;
		}
	}
	.uploadSelectContent {
		display: block;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			> * {
				margin-right: 8px;
			}
		}
		.pickListDropdown {
			> div {
				padding-top: 10px;
				> .header {
					color: #000;
					background-color: #fff;
					border: 1px solid #d9d9d9;
					> .icon {
						> div {
							color: #d9d9d9;
						}
					}
				}
				> .content {
					right: auto;
				}
			}
		}
	}

	.tableBlock {
		overflow-y: auto;
		margin-top: 24px;
		height: 100%;

		.eduItem {
			color: #ceff8f;
		}

		.subjectItem {
			color: #fff48e;
		}
	}

	.pageBlock {
		margin-top: 25px;
	}

	.selectUploadBtns {
		display: flex;
		justify-content: center;
		.btns {
			display: flex;
			> * {
				margin-right: 8px;
			}
		}
	}

	.ant-modal-title {
		color: #000;
	}
`;
export const UiWarp = styled.span`
	flex-wrap: wrap;
`;

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;
